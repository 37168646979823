<template>
  修改密码
</template>

<script>
export default {
  name: "change_password"
}
</script>

<style scoped>

</style>